import { tv } from 'tailwind-variants';
import { cva } from 'class-variance-authority';

export const container = cva({
  base: `
    data-[state=open]:animate-in
    data-[state=closed]:animate-out data-[state=closed]:fade-out-0
    data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95
    data-[state=open]:zoom-in-95
  `,
});

export const trigger = tv({
  base: `
    w-full min-h-12 h-12 px-1 rounded-xl flex items-center gap-size-base
    font-ubuntu font-semibold transition-all justify-between cursor-pointer
    duration-200 ease-in-out text-th-color-neutral-900 hover:bg-th-color-neutral-200
  `,
});

export const subItems = tv({
  base: `
    w-full min-h-9 h-9 rounded-border-radius-small flex items-center gap-size-base
    font-ubuntu font-medium transition-all text-medium px-size-base cursor-pointer
    duration-200 ease-in-out text-th-color-neutral-700 hover:bg-th-color-neutral-200
  `,
  variants: {
    active: {
      true: 'text-th-primary-color-main',
      false: '',
    },
  },
});



