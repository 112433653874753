"use client";

import useSWR from "swr";
import Image from "next/image";
import React, { useEffect, useRef } from "react";

import { fetcher } from "@aprosoja/api";
import { Skeleton } from "@aprosoja/ui";
import { cn, imageLoader } from "@aprosoja/utils";
import { GetAdvertisingsData } from "@aprosoja/types";

import { useIsVisible } from "./use-is-visible";
import useAdvertisingBanner from "./use-advertising-banner";

type AdvertisingBannerProps = {
  type: "top" | "middle" | "bottom";
  className?: string;
};

const AdvertisingBanner: React.FC<AdvertisingBannerProps> = ({
  type,
  className,
}: AdvertisingBannerProps) => {
  const {
    handleAddClickToAdvertising,
    getParamsBanners,
    handleAddViewToAdvertising,
  } = useAdvertisingBanner();
  const bannerRef = useRef<HTMLDivElement | null>(null);
  const isVisible = useIsVisible(bannerRef);

  const { data } = useSWR<GetAdvertisingsData>(
    [`/advertisings?status=true&type=${type}`],
    async ([url]) => fetcher(url),
    { revalidateOnMount: true, revalidateOnFocus: false }
  );

  const bannerData = data?.data[0];
  const paramsBannerData = getParamsBanners(type, bannerData);

  const handleClickBanner = async () => {
    if (bannerData) {
      await handleAddClickToAdvertising(bannerData.id);
      window.open(bannerData.link, "_blank");
    }
  };

  useEffect(() => {
    if (isVisible && bannerData) {
      handleAddViewToAdvertising(bannerData.id);
    }
  }, [bannerData, handleAddViewToAdvertising, isVisible]);

  return (
    <div
      ref={bannerRef}
      className={cn(
        `w-full flex justify-center ${type === "middle" ? "h-[280px]" : "min-[1281px]:h-[120px] max-[744px]:h-[40px] max-[745px]:h-[90px]"}`,
        className
      )}
    >
      {(paramsBannerData && paramsBannerData.bannerUrl && (
        <Image
          loader={imageLoader}
          priority
          src={paramsBannerData.bannerUrl}
          alt={`Banner de publicidade`}
          width={0}
          height={0}
          style={{
            width: paramsBannerData.width,
            height: paramsBannerData.height,
          }}
          className="cursor-pointer"
          onClick={handleClickBanner}
        />
      )) ||
        ((!paramsBannerData || !bannerData) && (
          <Skeleton
            className={`w-full h-full ${(type === "top" && "max-w-[970px]") || (type === "bottom" && "max-w-[970px]") || (type === "middle" && "max-w-[336px]")} bg-neutral-300`}
          />
        ))}
    </div>
  );
};

export { AdvertisingBanner };
