export const BREAKPOINT_PHONE_XSMALL = '320px';
export const BREAKPOINT_PHONE_SMALL = '360px';
export const BREAKPOINT_PHONE_MEDIUM = '412px';
export const BREAKPOINT_PHONE_LARGE = '430px';

export const BREAKPOINT_TABLET_SMALL = '744px';
export const BREAKPOINT_TABLET_MEDIUM = '834px';
export const BREAKPOINT_TABLET_LARGE = '1024px';

export const BREAKPOINT_DESKTOP_MEDIUM = '1280px';
export const BREAKPOINT_DESKTOP_LARGE = '1440px';
export const BREAKPOINT_DESKTOP_XLARGE = '1920px';

export const breakpoints = {
  phoneXsmall: BREAKPOINT_PHONE_XSMALL,
  phoneSmall: BREAKPOINT_PHONE_SMALL,
  phoneMedium: BREAKPOINT_PHONE_MEDIUM,
  phoneLarge: BREAKPOINT_PHONE_LARGE,
  tabletSmall: BREAKPOINT_TABLET_SMALL,
  tabletMedium: BREAKPOINT_TABLET_MEDIUM,
  tabletLarge: BREAKPOINT_TABLET_LARGE,
  desktopMedium: BREAKPOINT_DESKTOP_MEDIUM,
  desktopLarge: BREAKPOINT_DESKTOP_LARGE,
  desktopXlarge: BREAKPOINT_DESKTOP_XLARGE,
};
