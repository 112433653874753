import { useState, useCallback, useEffect } from "react";

const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);

  const updateMatches = useCallback(() => {
    setMatches(window.matchMedia(query).matches);
  }, [query]);

  useEffect(() => {
    updateMatches();

    window.addEventListener("resize", updateMatches);

    return () => {
      window.removeEventListener("resize", updateMatches);
    };
  }, [updateMatches]);

  return matches;
};

export { useMediaQuery };
