import { Children, useState } from "react";
import { usePathname, useRouter } from "next/navigation";

import { colors } from "@aprosoja/styles/tokens";
import { Icon } from "@aprosoja/ui";
import { cn } from "@aprosoja/utils";

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "./ui/collapsible";
import { DockItemProps } from "../dock-item/dock-item";
import * as s from "./styles";

type SubItem = {
  path: string;
  label: string;
  icon?: string;
  iconColor?: keyof typeof colors;
  onClick?: () => void;
};

type CollapsibleDockItemProps = DockItemProps & {
  subItems?: SubItem[];
};

export const CollapsibleDockItem = ({
  label,
  className,
  subItems,
}: CollapsibleDockItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const pathname = usePathname();
  const router = useRouter();

  const handleClickSubItem = (path: string) => {
    router.replace(path);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Collapsible open={isOpen} onOpenChange={handleToggle}>
      <CollapsibleTrigger asChild>
        <div className={s.trigger()}>
          {label}
          <Icon
            name={`keyboard_arrow_${!isOpen ? "down" : "up"}`}
            size="size-x6"
          />
        </div>
      </CollapsibleTrigger>
      {subItems && (
        <CollapsibleContent
          className={cn(
            `data-[state=open]:animate-in
          data-[state=closed]:animate-out data-[state=closed]:fade-out-0
          data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95
          data-[state=open]:zoom-in-95`,
            className
          )}
        >
          <div className="flex flex-col px-size-x2">
            {Children.toArray(
              subItems.map((subItem) => (
                <div
                  className={s.subItems({
                    active: pathname === `${subItem.path}`,
                    className,
                  })}
                  onClick={() => handleClickSubItem(subItem.path)}
                >
                  {subItem.label}
                </div>
              ))
            )}
          </div>
        </CollapsibleContent>
      )}
    </Collapsible>
  );
};
