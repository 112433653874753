"use client";

import { useLoadingPage } from "@aprosoja/stores";
import { Loading } from "@aprosoja/ui";

export const LoadingPage = () => {
  const { isLoading } = useLoadingPage();

  if (!isLoading) return null;

  return <Loading />;
};
