"use client";

import { useMemo } from "react";
import useSWR from "swr";

import { fetcherWithAuthorization } from "@aprosoja/api";
import { useUser } from "@aprosoja/stores";
import type { GetUserNotificationsData } from "@aprosoja/types";

const useUserNotifications = () => {
  const { user } = useUser();

  const { data: notificationsData } = useSWR<GetUserNotificationsData>(
    user ? [`/notifications/user/notifications`, user.access_token] : null,
    async ([url, token]) => fetcherWithAuthorization(url, token as string),
    {
      revalidateOnMount: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  );

  const allNotifications = useMemo(
    () => notificationsData?.data ?? [],
    [notificationsData]
  );

  const readNotifications = useMemo(
    () =>
      allNotifications.filter((notification) => notification.isRead === true),
    [allNotifications]
  );

  const unreadNotifications = useMemo(
    () =>
      allNotifications.filter((notification) => notification.isRead === false),
    [allNotifications]
  );

  return {
    allNotifications,
    readNotifications,
    unreadNotifications,
  };
};

export default useUserNotifications;
