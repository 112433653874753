"use client";

import { useCallback } from "react";

import { api, urls } from "@aprosoja/api";
import { useMediaQuery } from "@aprosoja/hooks";
import {
  BREAKPOINT_DESKTOP_MEDIUM,
  BREAKPOINT_TABLET_SMALL,
} from "@aprosoja/styles/breakpoints";
import { AdvertisingType } from "@aprosoja/types";

const useAdvertisingBanner = () => {
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_TABLET_SMALL})`);
  const isTablet = useMediaQuery(`(max-width: ${BREAKPOINT_DESKTOP_MEDIUM})`);

  const getParamsBanners = (
    type: string,
    bannerData: AdvertisingType | undefined
  ) => {
    let bannerUrl = "";
    let width = 0;
    let height = 0;

    switch (type) {
      case "top":
        if (isMobile) {
          bannerUrl = bannerData?.slimBannerUrl || "";
          width = 320;
          height = 40;
        } else if (isTablet) {
          bannerUrl = bannerData?.superBannerUrl || "";
          width = 728;
          height = 90;
        } else {
          bannerUrl = bannerData?.wideBannerUrl || "";
          width = 970;
          height = 120;
        }
        break;
      case "bottom":
        if (isMobile) {
          bannerUrl = bannerData?.slimBannerUrl || "";
          width = 320;
          height = 40;
        } else if (isTablet) {
          bannerUrl = bannerData?.superBannerUrl || "";
          width = 728;
          height = 90;
        } else {
          bannerUrl = bannerData?.wideBannerUrl || "";
          width = 970;
          height = 120;
        }
        break;
      case "middle":
        bannerUrl = bannerData?.squareBannerUrl || "";
        width = 336;
        height = 280;
        break;
      default:
        return null;
    }

    return { bannerUrl, width, height };
  };

  const handleAddClickToAdvertising = useCallback(
    async (id: string | number) => {
      try {
        await api.patch(`${urls.advertisings}/incrementClick/${id}`);
      } catch (err) {
        console.log(err);
      }
    },
    []
  );

  const handleAddViewToAdvertising = useCallback(
    async (id: string | number) => {
      try {
        await api.patch(`${urls.advertisings}/incrementView/${id}`);
      } catch (err) {
        console.log(err);
      }
    },
    []
  );

  return {
    handleAddClickToAdvertising,
    getParamsBanners,
    handleAddViewToAdvertising,
  };
};

export default useAdvertisingBanner;
