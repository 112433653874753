import React, { Children } from "react";

import { IconButton, Text, Tabs } from "@aprosoja/ui";
import { CardNotification } from "../../../card-notification/card-notification";
import useUserNotifications from "./use-user-notifications";
import type { UserNotificationType } from "@aprosoja/types";

export type NotificationsModalProps = {
  onClose: (closed: false) => void;
  isOpen: boolean;
};

export const NotificationsModal: React.FC<NotificationsModalProps> = ({
  onClose,
  isOpen = false,
}: NotificationsModalProps) => {
  const { allNotifications, readNotifications, unreadNotifications } =
    useUserNotifications();

  const renderNotifications = (notifications: UserNotificationType[]) => (
    <div className="flex flex-col gap-size-x4 pr-size-x2">
      {Children.toArray(
        notifications.map((notification) => (
          <CardNotification data={notification} />
        ))
      )}
    </div>
  );

  const tabs = [
    {
      label: "Todas",
      value: "all",
      content: renderNotifications(allNotifications),
    },
    {
      label: "Lidas",
      value: "read",
      content: renderNotifications(readNotifications),
    },
    {
      label: `Não lidas (${unreadNotifications.length})`,
      value: "unread",
      content: renderNotifications(unreadNotifications),
    },
  ];

  return (
    <div
      className={`bg-th-color-neutral-100 h-full absolute z-10 flex flex-col gap-size-x6 pt-size-x9 pl-size-x4 pr-size-x2 transition-all ease-in-out duration-500 overflow-x-hidden ${isOpen ? "w-[400px] translate-x-0 left-[230px]" : "translate-x-[-400px]"}`}
    >
      <section className="w-full flex justify-between items-center gap-size-x2">
        <Text type={"headingM"}>Notificações</Text>
        <IconButton
          variant="ghost"
          name="close"
          size="large"
          onClick={() => onClose(false)}
        />
      </section>
      <Tabs initialActive="all" tabs={tabs} />
    </div>
  );
};
