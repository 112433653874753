"use client";
import React, { useState } from "react";
import Image from "next/image";

import { IconButton, Text } from "@aprosoja/ui";
import { imageLoader } from "@aprosoja/utils";

type PixInformationsProps = {
  code: string;
  imageBase64: string;
  qrCodeSize?: "small" | "medium";
};

export const PixInformations = ({
  code,
  imageBase64,
  qrCodeSize = "medium",
}: PixInformationsProps) => {
  const [copySuccess, setCopySuccess] = useState(false);

  return (
    <div className="flex flex-col w-full gap-size-x2">
      <Text type="labelL">Código Pix - Copie e cole</Text>
      <div className="flex flex-col w-full gap-size-base">
        <div className="flex w-full px-size-x2 py-size-x3 items-center justify-between rounded-border-radius-small border-border-width-thin bg-neutral-100">
          <div className="w-full overflow-x-auto contain-inline-size">
            <Text type="paragraphM">{code}</Text>
          </div>
          <IconButton
            name="stack"
            variant="ghost"
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(code ?? "");
              setCopySuccess(true);
            }}
          />
        </div>
        {copySuccess && (
          <Text type="labelS" color="th-primary-color-main">
            Código copiado
          </Text>
        )}
      </div>
      <Text type="labelL">QR Code Pix - Aponte a Câmera para o QR Code</Text>
      <div className="flex w-full justify-center">
        <Image
          loader={imageLoader}
          src={imageBase64 ? `data:image/png;base64,${imageBase64}` : ""}
          alt="pix-qrcode"
          width={qrCodeSize === "medium" ? 400 : 320}
          height={qrCodeSize === "medium" ? 400 : 320}
        />
      </div>
      <div className="flex flex-col w-full gap-size-x4 p-size-x4 rounded-border-radius-small bg-neutral-100">
        <Text type="headingS">Pagar com Pix é fácil e rápido!</Text>
        <div className="flex flex-col w-full gap-size-base">
          <Text type="paragraphL">
            • Abra o aplicativo do seu banco ou empresa de pagamento;
          </Text>
          <Text type="paragraphL">
            • Clique em pagar pix no seu aplicativo e aponte a câmera para o QR
            Code Pix;
          </Text>
          <Text type="paragraphL">
            • Pronto, agora é só finalizar o pagamento e aguardar a aprovação;
          </Text>
          <Text type="paragraphL">
            • Se preferir, copie e cole o código pix no seu aplicativo ou site
            pix.
          </Text>
        </div>
      </div>
    </div>
  );
};
