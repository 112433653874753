"use client";

import React, { useCallback, useEffect, useRef } from "react";
import dayjs from "dayjs";
import Link from "next/link";

import { Card, Text } from "@aprosoja/ui";
import { type UserNotificationType } from "@aprosoja/types";
import { api } from "@aprosoja/api";
import { useIsVisible } from "./use-is-visible";

type CardNotificationProps = {
  data: UserNotificationType;
};

export function CardNotification({ data }: CardNotificationProps) {
  const notificationRef = useRef<HTMLDivElement | null>(null);
  const isVisible = useIsVisible(notificationRef);

  const handleMarkAsRead = useCallback(async (id: string | number) => {
    try {
      await api.patch(`notifications/status/${id}`);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (isVisible && data.id && !data.isRead) {
      const timeoutId = setTimeout(() => {
        handleMarkAsRead(data.id);
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [data, handleMarkAsRead, isVisible]);

  const content = (
    <Card ref={notificationRef}>
      <div className="w-full flex justify-between">
        <Text type="headingXS">{data.notification.title}</Text>
        {!data.isRead && (
          <div className="w-[12px] h-[12px] bg-th-primary-color-main rounded-border-radius-pill" />
        )}
      </div>
      <Text type="paragraphM">{data.notification.text}</Text>
      <Text type="paragraphM" color="th-color-neutral-700">
        {dayjs(data.notification.createdAt).format("DD MMM YYYY HH:mm")}
      </Text>
    </Card>
  );

  return data.notification.link ? (
    <Link href={data.notification.link}>{content}</Link>
  ) : (
    content
  );
}
