"use client";
import React, { useState } from "react";

import { Button, IconButton, Text } from "@aprosoja/ui";
import Link from "next/link";

type BankSlipInformationsProps = {
  code: string;
  bankSlipLink: string;
};

export const BankSlipInformations = ({
  code,
  bankSlipLink,
}: BankSlipInformationsProps) => {
  const [copySuccess, setCopySuccess] = useState(false);

  return (
    <div className="flex flex-col w-full gap-size-x2">
      <Text type="labelL">Código de barras - Copie e cole</Text>
      <div className="flex flex-col w-full gap-size-base">
        <div className="flex w-full px-size-x2 py-size-x3 items-center justify-between rounded-border-radius-small border-border-width-thin bg-neutral-100">
          <div className="w-full overflow-x-auto contain-inline-size">
            <Text type="paragraphM">{code}</Text>
          </div>
          <IconButton
            name="stack"
            variant="ghost"
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(code ?? "");
              setCopySuccess(true);
            }}
          />
        </div>
        {copySuccess && (
          <Text type="labelS" color="th-primary-color-main">
            Código copiado
          </Text>
        )}
      </div>
      <div className="flex justify-center py-size-x4">
        <Link href={bankSlipLink} target="_blank">
          <Button variant="outlined">Abrir boleto</Button>
        </Link>
      </div>
      <div className="flex flex-col w-full gap-size-x4 p-size-x4 rounded-border-radius-small bg-neutral-100">
        <div className="flex flex-col w-full gap-size-base">
          <Text type="paragraphL">
            • Abra o boleto e utilize o código de barras para pagar diretamente
            no aplicativo do seu banco;
          </Text>
          <Text type="paragraphL">
            • Se preferir, copie o código acima e cole na opção de pagamento via
            boleto no seu banco online;
          </Text>
          <Text type="paragraphL">
            • A confirmação pode levar até 3 dias úteis;
          </Text>
        </div>
      </div>
    </div>
  );
};
