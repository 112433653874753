import { tv } from 'tailwind-variants';

export const DockItemButton = tv({
  base: `
    w-full min-h-12 h-12 px-1 rounded-xl flex items-center gap-size-base
    font-ubuntu font-semibold transition-all cursor-pointer whitespace-nowrap
    duration-200 ease-in-out text-th-color-neutral-900 hover:bg-th-color-neutral-200
  `,
  variants: {
    active: {
      true: 'text-th-primary-color-main',
      false: 'cursor-pointer',
    },
  },
});
