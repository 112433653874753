import { cva } from 'class-variance-authority';

export const container = cva('flex flex-col items-center justify-center pt-size-x40 w-full h-full');

export const content = cva('flex flex-col items-center justify-center gap-[88px] w-full');

export const wrappedForm = cva(
  'flex flex-col items-center justify-center py-size-x10 px-size-x8 gap-size-x8 w-full tabletMedium:w-[736px]',
);

export const form = cva('flex flex-col gap-size-x4 w-full');
