import { usePathname } from "next/navigation";

import { Icon } from "@aprosoja/ui";
import { colors } from "@aprosoja/styles/tokens";

import * as s from "./styles";


export type DockItemProps = {
  path?: string;
  label: string;
  iconLeft?: string;
  iconRight?: string;
  iconColor?: keyof typeof colors;
  className?: string;
  active?: boolean;
  onClick?: () => void;
};

export const DockItem = ({
  path,
  onClick,
  label,
  iconLeft,
  iconRight,
  iconColor = "th-color-neutral-700",
  className = "",
  active = false,
}: DockItemProps) => {
  const pathname = usePathname();

  const handleClick = () => {
    if (pathname === `${path}`) return;
    onClick?.();
  };

  return (
    <div
      className={s.DockItemButton({
        active: active || pathname === `${path}`,
        className,
      })}
      onClick={handleClick}
    >
      {iconLeft && <Icon name={iconLeft} color={iconColor} />}
      {label}
      {iconRight && <Icon name={iconRight} color={iconColor} />}
    </div>
  );
};
