import { IRoute } from "@aprosoja/types";

export const panelNavigationMain: IRoute[] = [
  {
    icon: "",
    label: "Dashboard",
    path: "/dashboard",
  },
  {
    icon: "",
    label: "Notícias",
    path: "",
    subItems: [
      {
        icon: "",
        label: "Criar notícia",
        path: "/noticia",
      },
      {
        icon: "",
        label: "Lista de notícias",
        path: "/noticias",
      },
      {
        icon: "",
        label: "Tags",
        path: "/tags",
      },
      {
        icon: "",
        label: "Autores",
        path: "/autores",
      },
    ],
  },
  {
    icon: "",
    label: "Categorias",
    path: "/categorias",
  },
  {
    icon: "",
    label: "Anúncios",
    path: "/anuncios",
  },
  {
    icon: "",
    label: "Notificações",
    path: "/notificacoes",
  },
  {
    icon: "",
    label: "Diretoria",
    path: "",
    subItems: [
      {
        icon: "",
        label: "Criar diretoria",
        path: "/diretor",
      },
      {
        icon: "",
        label: "Lista de diretoria",
        path: "/diretoria",
      },
      {
        icon: "",
        label: "Mandatos",
        path: "/mandatos",
      },
    ],
  },
  {
    icon: "",
    label: "Associados",
    path: "/associados",
  },
  {
    icon: "",
    label: "Dúvidas",
    path: "",
    subItems: [
      {
        icon: "",
        label: "Criar dúvida",
        path: "/duvida",
      },
      {
        icon: "",
        label: "Lista de dúvidas",
        path: "/duvidas",
      },
      {
        icon: "",
        label: "Temas",
        path: "/temas",
      },
    ],
  },

  {
    icon: "",
    label: "Parâmetros",
    path: "/parametros",
  },

  {
    icon: "",
    label: "Relatórios",
    path: "/relatorios",
  },
  {
    icon: "",
    label: "Mídias",
    path: "/midias",
  },
  {
    icon: "",
    label: "Informações pessoais",
    path: "/informacoes-pessoais",
  },
  {
    icon: "",
    label: "Propriedades",
    path: "/propriedades",
  },
];

export const panelNavigationFooter: IRoute[] = [
  {
    icon: "password",
    label: "Alterar senha",
    path: "/alterar-senha",
  },
  {
    icon: "help",
    label: "Central de ajuda",
    path: "/central-de-ajuda",
  },
];

export const membersNavigationMain: IRoute[] = [
  {
    icon: "",
    label: "Informações pessoais",
    path: "/informacoes-pessoais",
  },
  {
    icon: "",
    label: "Propriedades",
    path: "/propriedades",
  },
  {
    icon: "",
    label: "Associação",
    path: "/associacao",
  },
  {
    icon: "",
    label: "Informes",
    path: "/informes",
  },
];

export const membersNavigationFooter: IRoute[] = [
  {
    icon: "password",
    label: "Alterar senha",
    path: "/alterar-senha",
  },
  {
    icon: "help",
    label: "Central de ajuda",
    path: "/central-de-ajuda",
  },
];
