"use client";

import React from "react";
import { useForm, Controller } from "react-hook-form";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { signIn } from "next-auth/react";
import { zodResolver } from "@hookform/resolvers/zod";

import { Button, Text, TextField } from "@aprosoja/ui";
import { LoginFormValues, loginFormSchema } from "./login-schema";
import * as s from "./styles";
import imageLogo from "../../public/logo.png";

type FormLoginProps = {
  title: string;
  linkToGoBack?: string;
};

export function FormLogin({ title, linkToGoBack }: FormLoginProps) {
  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<LoginFormValues>({
    resolver: zodResolver(loginFormSchema),
  });
  const { push } = useRouter();

  const handleSubmitForm = async (dataValues: LoginFormValues) => {
    const result = await signIn("credentials", {
      email: dataValues.email,
      password: dataValues.password,
      redirect: false,
    });

    if (result?.error) {
      setError("password", {
        type: "manual",
        message: "E-mail ou senha incorretos. Tente novamente.",
      });
    } else {
      push("/");
    }
  };

  return (
    <div className={s.container()}>
      <div className={s.content()}>
        <Image
          src={imageLogo}
          priority
          alt="logo Aprosoja"
          height={103}
          width={352}
        />
        <section className={s.wrappedForm()}>
          <Text type="headingXL">{title}</Text>
          <form className={s.form()} onSubmit={handleSubmit(handleSubmitForm)}>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  label="E-mail"
                  type="email"
                  errorMessage={fieldState?.error?.message}
                  onChange={field.onChange}
                  size="large"
                  className="border-th-color-neutral-200 rounded-border-radius-small"
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  label="Senha"
                  errorMessage={
                    fieldState?.error?.message || errors.password?.message
                  }
                  type="password"
                  onChange={field.onChange}
                  size="large"
                  className="border-th-color-neutral-200 rounded-border-radius-small"
                />
              )}
            />
            <div className="flex items-center justify-end w-full">
              <Button
                onClick={() => push("/recuperar-senha")}
                type="button"
                className="rounded-border-radius-small"
                variant="text"
              >
                Recuperar senha
              </Button>
            </div>
            <Button
              size="large"
              type="submit"
              className="rounded-border-radius-small"
              loading={isSubmitting}
            >
              Entrar
            </Button>
            {linkToGoBack && (
              <Button
                onClick={() => push(linkToGoBack)}
                type="button"
                size="large"
                className="text-neutral-900"
                variant="text"
              >
                Voltar
              </Button>
            )}
          </form>
        </section>
      </div>
    </div>
  );
}
